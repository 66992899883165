<template>
  <h1>Systemisches Denken und Handeln</h1>
  <p>
    Für das Systemische Denken und Handeln wird gerne die Metapher eines Mobiles
    gewählt. Stößt man irgendeine Stelle des Mobiles an, wird es sich als Ganzes
    bewegen. Schiebt man einen Teil in eine andere Position, so hat das
    Auswirkungen auf das gesamte Mobile. Der eine Bereich vibriert vielleicht
    ganz leicht, während es an anderen Punkten heftig und stark hin und her
    wirbelt. Im systemischen Denken, kommt es dann in einem System zu Problemen,
    wenn das Mobile sich so verhakt und verheddert, dass es an einer Stelle
    erstarrt und zum Stillstand kommt.
  </p>
  <p>
    Aus systemischer Sicht entstehen hier in Systemen, zu denen jeder Mensch in
    irgendeiner Weise gehört, (Partnerschaften, Familie, Gruppen, Schulklassen,
    Teams, Institutionen, Organisationen) Probleme, Konflikte und Symptome.
    Diese können sich dann im emotionalen-psychischen-sozialen Verhalten, in den
    zwischenmenschlichen Beziehungen, in der Kommunikationsfähigkeit, aber auch
    auf geistig/mentaler und kognitiver Lernebene, oder in psychisch-somatischen
    Krankheitsbildern zeigen.
  </p>

  <h2>Vom Problemland ins Lösungsland</h2>
  <p>
    Systemisches Handeln hat das Ziel, Systeme oder Teile eines Systems die fest
    gehakt und erstarrt sind, dabei zu unterstützen, das „Ganze wieder in
    fließende Bewegung“ zu bringen. Es wird davon ausgegangen, dass die Lösungen
    für bestehende Probleme bereits im System vorhanden sind, sie müssen nur
    noch entdeckt und angenommen werden.
  </p>
  <p>
    Der Therapeut ist nicht derjenige, der modifiziert, repariert und
    korrigiert. Mit Hilfe systemischer Methoden unterstützt der Therapeut den
    Klienten oder Familiensysteme, ungeeignete Entwicklungs- und
    Gefühlsblockierende Kommunikations-, Verhaltens- und Glaubensmuster
    ausfindig zu machen.
  </p>
  <p>
    Gleichzeitig ist es Aufgabe des Systemischen Therapeuten, dem Klienten oder
    den Systemen, Hilfestellung dabei zu geben, sich der vorhandenen Ressourcen
    (Potentiale, Strategien, Fähigkeiten, Talente) bewusst zu werden, um diese
    bei der Lösungsfindung nutzen zu können.
  </p>

  <h2>Systemische Familientherapie</h2>
  <p>
    Das grundsätzliche Ziel der Systemischen Familientherapie ist es, die
    Autonomie und den Selbstwert jeder einzelnen Person/jedes
    Familienmitgliedes, aber auch den Zusammenhalt zu stärken. Die
    Kommunikation, der Austausch und die Beziehungen untereinander sollen
    verbessert werden.
  </p>
  <div class="img">
    <img
      src="../assets/Systemische_Familientherapie.png"
      alt="Darstellung einer Familie"
    />
  </div>
  <p>
    In der Familientherapie, wird davon ausgegangen, dass das Verhalten
    einzelner Familienmitglieder, das Beziehungsgefüge bestimmt. Probleme,
    Konflikte und Symptome können auf Grund von Strukturen (Mehrgenerationen,
    Patchwork Familien, Trennung, alleinerziehend) Rollendefinitionen (Hausfrau,
    Geldverdiener, Karriere) und Festschreibungen (Sündenbock, schwarzes Schaf,
    Problemkind, Sonnenscheinkind, Clown) entstehen, welche durch das
    Familiensystem selber oder durch soziale Subsysteme (Schule, Kita,
    Arbeitgeber) vorgenommen werden. Diese wirken und beeinflussen - oft
    unbewusst - wechselseitig sowohl den Einzelnen, das Familiengefüge und die
    Subsysteme. Erkrankt z. B. ein Familienmitglied oder wird arbeitslos,
    betrifft das unmittelbar zwar den Einzelnen, aber diese Ereignisse wirken
    sich auf alle - und das - ganz unterschiedlich aus.
  </p>
  <p>
    So können das Bettnässen eines Kindes oder unerwünschte Verhaltensweisen,
    als ein Symptom „auffallend“ darauf hinweisen, das im System etwas aus der
    Balance geraten ist. Die „Symptomträger“ werden „entlastet“ - wenn für die
    „eigentlichen“, oft unbewussten Probleme und Konflikte, Lösungen gefunden
    werden - oftmals verändern oder lösen sich dann auch die „Symptome“. Darum
    richtet die Systemische Familientherapie ihr Augenmerk darauf, welche
    Dynamiken im System vorhanden sind, bzw. in Krisensituationen entstehen, was
    sich zwischen den einzelnen Mitgliedern abspielt, wie sie kommunizieren und
    wie sie miteinander umgehen.
  </p>
  <p>
    Durch methodische Angebote, kann das System sich die
    (negativen/blockierenden) wechselwirksamen Reaktionen, Aktionen,
    Verhaltensweisen und Kommunikationsstrukturen bewusst machen und Lösungen
    finden, diese zu verändern. Der Lösungsweg ist immer eine Annäherung an eine
    neues Systemverhalten, dass eigene Entwicklungsmöglichkeiten beinhaltet -
    und Zeit braucht - ausprobiert und umgesetzt - oder als ungeeignet - wieder
    verworfen zu werden.
  </p>
  <p>
    Jeder hat andere Ideen wie die Probleme gelöst werden könnten, die sich
    nicht selten widersprechen, aber dennoch oftmals zufrieden stellende
    Ergebnisse beinhalten. Der Klient/das System ist „Experte“ für seine
    Probleme und Lösungen! „Veränderungen“ liegen in dessen Verantwortung!
  </p>
  <div class="img">
    <img
      src="../assets/Systemische_Veraenderung.png"
      alt="Ich mag Veränderung. Ich hoffe nur, das ändert sich nicht."
    />
  </div>

  <p>
    Systemische Therapie bringt Bewegung, Veränderung und Balance in Systeme und
    unterstützt die Menschen in ihren Prozessen des Lebens:
  </p>
  <ul>
    <li>Individuelle Persönlichkeitsentwicklung</li>
    <li>Paar werden - Paar sein - Paar bleiben</li>
    <li>Kinderwunsch - Schwangerschaft - Geburt</li>
    <li>Eltern werden - Eltern sein</li>
    <li>Kind/er - Geschwister</li>
    <li>Familie werden - Familie sein - Familie bleiben</li>
    <li>Trennen - Loslassen - Neubeginn</li>
    <li>Abschied/Tod - Trauer - Hoffnung - Zuversicht</li>
  </ul>

  <h2>Systemische Supervision</h2>
  <p>
    Jede Organisation, Institution, Gruppe, jedes Team ist in Strukturen
    eingebunden, in denen es bestimmte Rahmenbedingungen gibt. Es treffen
    gleiche oder verschiedene Berufsgruppen mit den dazu gehörenden spezifischen
    Aufgaben aufeinander. Gefüllt und lebendig werden die Gefüge und Systeme
    durch die Menschen, die sich hier aus den gleichen oder unterschiedlichen
    Motiven und Motivationen heraus treffen, begegnen und (zusammen) arbeiten.
  </p>
  <p>
    Viele „ICHS“ im „WIR“ - zugehörig zu einer übergeordneten Struktur - haben
    unterschiedliche Ideen, Visionen, Vorstellungen und Ziele. Jedes „ICH“ ist
    ein Individuum mit einer eigenen Geschichte, mit Talenten und Fähigkeiten,
    persönlichen Stärken und Schwächen, mit Wünschen und Bedürfnissen, mit
    Vorlieben und Abneigungen mit Besonderheiten und Eigenheiten usw.
  </p>
  <div class="img">
    <img
      src="../assets/Systemische_Supervision_Team.png"
      alt="Ein Team bestehend von vielen Individuen"
    />
  </div>
  <p>
    Treffen die aufgezeigten Prämissen aufeinander, beginnen unterschiedliche
    Dynamiken zu wirken. Viele zeichnen sich durch positive Wachstumsprozesse,
    Effizienz und Effektivität, durch Freude an der Arbeit und dem gemeinsamen
    Tun aus. Visionen werden Wirklichkeit und Ziele erreicht. In bestimmten
    Situationen und Phasen kann es zum „Stillstand“ kommen. Es tauchen Probleme
    und Konflikte auf, die sich scheinbar nicht (wie gewohnt) lösen lassen.
  </p>
  <p>
    Kommunikation findet nicht mehr - oder in unpassender und ungeeigneter - Art
    und Weise statt. Die Arbeit und die Aufgaben bereiten keine Freude mehr,
    Unlust, (subjektiv empfundene) Überlastung, bis hin zum Burnout, sind
    häufige Symptome für ungeklärte Situationen, Probleme und Konflikte im
    Berufsleben.
  </p>

  <p>Hier bietet die Systemische Supervision Hilfestellung:</p>
  <ul>
    <li>
      die bestehende Situation aus unterschiedlichen Perspektiven zu betrachten
    </li>
    <li>die Stärkung der Problemlösungskompetenz</li>
    <li>zu neuer Kommunikation</li>
    <li>Aufgaben (neu) zu definieren</li>
    <li>Rollenverständnisse zu klären</li>
    <li>Leitungsaufgaben zu bewältigen</li>
    <li>
      bei der Klärung von Konflikten mit Kollegen, Mitarbeitern, Vorgesetzten
      und Institutionen
    </li>
    <li>
      Systemische Supervision beinhaltet eine prozessorientierte Vorgehensweise,
      deren Umfang und Dauer die Auftraggeber bestimmen.
    </li>
    <li>
      Dabei bilden die Ziele der Supervisanden die Grundlage für die lösungs-
      und ressourcenorientierte Arbeit.
    </li>
  </ul>
  <p>
    Ich biete Einzel-, Gruppen-, Team- und kollegiale Supervision an. Zudem kann
    auch eine Struktur- oder Organisationsaufstellung gebucht werden. Diese Form
    der Arbeit kann verschiedene Prozesse, z.B. Umstrukturierungen begleiten.
    „Diskrepanzen“ zwischen Vorhaben/Planungen und den gegenwärtigen Realitäten,
    wie Ressourcen/Motivation, können sichtbar werden.
  </p>

  <h2>Systemische - Kinesiologische Familien- und Strukturaufstellungen</h2>
  <div class="img">
    <img
      src="../assets/Systemische_Familien_und_Strukturaufstellung.png"
      alt=""
    />
  </div>

  <h3>Systemische - phänomenologische Familien/Aufstellungen</h3>
  <p>
    Virgina Satir, eine der Begründerinnen der Systemischen Familientherapie,
    veröffentlichte 1969 die Familienaufstellung unter dem Namen
    „Familienskulptur/Familienrekonstruktion“. Familien stellen sich zu einem
    Thema, Problem, Konflikt, oder ihre Beziehungen im Raum zueinander auf.
    Dieses Verfahren kann auch mit Stellvertretern (Statisten) durchgeführt
    werden. Familiensysteme und ihre einzelnen Mitglieder sind auf einer tiefen
    und oft auch unbewussten Ebene miteinander verbunden, unabhängig davon, ob
    sie sich lieben, einen Konflikt haben, im Kontakt sind, sich ignorieren oder
    nie kennengelernt haben.
  </p>
  <p>
    Über die „räumliche Anordnung“ in der Aufstellungsarbeit, kann
    herausgearbeitet werden, welche Position/Rolle die einzelnen
    Familienmitglieder einnehmen/haben und welche Bedeutung/Wirksamkeit, das in
    der Familie und für den Einzelnen hat. Familienbeziehungen,
    Beziehungsgeflechte, Rollen, Verstrickungen, blockierende Muster und
    Konflikte, werden zunächst „nonverbal sichtbar“ und die
    „räumlich-körperliche Haltung“ kann von den subjektiven Empfindungen (von
    dem was gesagt, gedacht, geglaubt, gefühlt und erlebt wird) abweichen und
    widersprüchlich sein.
  </p>
  <p>
    Phänomene und Dynamiken erhalten einen Raum und beginnen sichtbar werdend zu
    wirken. Der Familientherapeut kann sich über das „dargestellte Bild“ und
    über die dazu erfolgenden Reaktionen Informationen einholen und mit dem
    System besprechen und „Lösungen“ erarbeiten.
  </p>
  <h3>Aufstellungen in der Praxis BalanceRäume</h3>
  <p>
    Ich biete systemische Aufstellungen für meine Klienten an und nutze
    kinesiologische Methoden, die hilfreich sein können, das Geschehen und
    Geschehene gut zu verarbeiten. Ein vorab erstelltes „Genogramm“ kann als
    Grundlage dienen, bzw. mit in die Aufstellung einbezogen werden.
  </p>
  <p>
    Es ist möglich, als Familie selber oder mit in einer von mir organisierten
    und bekannten Arbeitsgruppe, aus der Stellvertreter gewählt werden können,
    eine Aufstellung zu machen. Wenn Sie an Aufstellungen interessiert sind und
    diese Arbeit kennen lernen, als Statist mitwirken oder selber zu einem
    Thema/Problem aufstellen möchten, nehmen Sie gerne Kontakt zu mir auf.
  </p>

  <h2>
    Systemisch - Kinesologische Aufstellungen und Arbeit mit dem Genogramm
  </h2>
  <p>
    Das Genogramm ist ein Schema (Piktogramm), in dem epochale,
    zeitgeschichtliche, familiäre und individuelle Daten, Erlebnisse und
    Schicksale dargestellt werden. „Familiendynamiken“ können mit Hilfe des
    Genogramms durch „Raum und Zeit“ sichtbar gemacht und damit verfolgt werden.
    Es erlaubt einen „Blick“ hinter die „Kulissen“, die eigenen Themen können
    dadurch in einem anderen Licht betrachtet werden. Herausforderungen,
    Probleme, Schicksal und nicht verarbeitete Traumata der Ahnen, stehen
    oftmals im Kontext zu den eigenen Thematiken und zeigen sich in der
    Gegenwart in unterschiedlichen Bereichen und über verschiedene Symptome.
  </p>
  <p>
    Mit Hilfe eines Genogrammes können hier Zusammenhänge hergestellt - und
    blockierende Verhaltensmuster, negative Glaubenssysteme, Übertragungen und
    Beeinflussungen oder Verstrickungen - erkannt werden. Der Klient kann „neue
    Blickwinkel“ einnehmen, die es ihm ermöglichen „neue Betrachtungsweisen“ für
    seine „Probleme, Herausforderungen und Symptome“ entwickeln zu können. Das
    Genogramm dient in der von mir angebotenen Aufstellungsarbeit der „Bildung
    von Hypothesen“, welche gegebenenfalls dazu beitragen, sich selbst, die
    eigene Position oder die der Familie oder die eines Familienmitgliedes auf
    eine andere Weise wahrnehmen zu können.
  </p>
  <p>
    Emotionale Themen sollen „entgiftet“ d. h. normalisiert und damit neu
    formulierbar werden. Es besteht die Möglichkeit, den Blick auf die
    Ressourcen und Potentiale zu richten, welche die Vorfahren genutzt haben, um
    schwierige Lebensbedingungen und persönliche Herausforderungen zu meistern.
    Sich dieser „bewusst“ zu werden, birgt gleichzeitig die Chance, sie zur
    „aktiven Lösungsfindung“ für das eigene Thema nutzen zu können.
  </p>
  <p>
    Ich habe in meiner Methode, dem Reeducating I.D.© ein Genogramm auf der
    Basis der Fünf Elemente aus der chinesischen Energielehre entwickelt und
    nutze dieses für die Aufstellungsarbeit.
  </p>

  <h3>Methodische Angebote und Interventionen</h3>
  <p>
    Methodische Angebote aus der Kinesiologie und der systemischen Arbeit können
    während Aufstellung angeboten werden. Diese können dabei hilfreich sein,
    Blockaden zu lösen und den Klienten dabei unterstützen, negative Muster zu
    erkennen und zu durchbrechen und an seinen Ressourcen orientiert, eigene
    Lösungen zu finden.
  </p>
  <p>Methodische Angebote aus der systemischen Therapie:</p>
  <ul>
    <li>Überlebensstrategien- und Ressourcenaufstellung</li>
    <li>Skulpturen, Rollenspiele,</li>
    <li>Partsparty (Persönlichkeitsanteile stellen)</li>
    <li>kreative, gestalterische, musische Elemente</li>
  </ul>
  <p>Methodische Angebote aus der Kinesiologie und dem Reeducating I.D.©</p>
  <ul>
    <li>Reeducating I.D. Fünf-Elemente-Genogramm©</li>
    <li>
      Integration von negativen Schwangerschafts- und Geburtserleben und ggf.
      von Restreaktionen frühkindlichen Reflexe
    </li>
    <li>
      Blütenessenzen (Reeducating I.D. Essenzen©, Rosalinde Blüten, australische
      Buschblüten u.a.)
    </li>
    <li>Brain Gym Übungen® und TFH-Muskelbalancen</li>
    <li>Integrierende Körperübungen und Klopfakupressur zu den Meridianen</li>
    <li>Verhaltensbarometer</li>
    <li>Assoziative Bildkarten</li>
  </ul>

  <h2>Themenbereiche für eine Aufstellungsarbeit</h2>
  <ul>
    <li>Konflikte in der Beziehung, in der Familie, im Beruf</li>
    <li>Berufliche und schulische Themen, Burnout und Mobbing</li>
    <li>wiederkehrende negative Lebensmuster (Süchte, Ängste, Zwänge)</li>
    <li>Partnerschaft, Bindung, Nähe und Kontakt, Sexualität,</li>
    <li>Trennung, Verlust, Tod, Trauer</li>
    <li>
      Unfälle, Schicksalsschläge, Negativerfahrungen in der Sozialisation,
    </li>
    <li>psychische und körperliche Gewalt</li>
    <li>
      Schwangerschaft, Geburt, Eltern-Kind-Beziehung, Adoptions- und
      Pflegekinder
    </li>
    <li>körperliche und psychische Gesundheit</li>
  </ul>
</template>

<script>
export default {
  name: "Systemisches_Arbeiten"
};
</script>

<style></style>
